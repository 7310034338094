import * as styles from "./app.module.scss";
import cn from 'classnames';
import {HorizontalSlider, ProgressBar} from "ui";
import {MachineProperty, RecommendedMachine} from "ui/src/types";

const loginLink = window.app.preloadState.loginLink;
const isLoggedIn = window.app.preloadState.isLoggedIn;

const translationState = (window as any).app.preloadState.translation;
const recommendedMachines: RecommendedMachine[] = (window as any).app.preloadState.recommendedMachines;

export default function App() {
    return <>
        <RecommendedMachinesLarge />
        <RecommendedMachinesSmall />
    </>
}

const RecommendedMachinesSmall = () => {
    return <HorizontalSlider
        hideArrows={true }
        isDarkIndicatorMode={true}
        height={930}
        className={cn(styles.recommendedMachinesSmallContainer, "d-md-block d-none")}
        nodes={recommendedMachines.map((machine: RecommendedMachine, index: number) => <RecommendedMachineItem machine={machine} key={index} index={index} />)}
    />
}

const RecommendedMachinesLarge = () => {
    return <div className={cn(styles.recommendedMachinesContainer, "d-md-none")}>
        {recommendedMachines.map((machine: RecommendedMachine, index: number) => <RecommendedMachineItem machine={machine} key={index} index={index} />)}
    </div>
}

const RecommendedMachineItem = ({ machine, index }: { machine: RecommendedMachine, index: number }) => {
    return <a href={machine.url} key={index} className={styles.recommendedMachine}>
        <div className={styles.machinesImageContainer}>
            {machine.showVolvoIcon && <img className={styles.volvoIcon} src="../assets/logos/volvoIcon.svg" />}
            {machine.imageUrl && <img className={styles.machineImage} src={machine.imageUrl} />}
        </div>

        <div className={styles.machineTextClontainer}>
            <div className={styles.machineDisplayNames}>
                <p className={styles.machineModelName}>{machine.category}</p>
                <p className={styles.machineModelCode}>{machine.displayName}</p>
            </div>

            <div className={styles.machineProperties}>
                {machine.machineProperties.map((property: MachineProperty, index: number) => (
                    <div className={styles.machinePropertiesUnit} key={index}>
                        <div className={styles.machinePropertiesUnitTitle}>
                            <p>{property.name} ({property.unit})</p>
                            <p>{property.value}</p>
                        </div>
                        <div className={styles.machinePropertiesUnitSlider}>
                            <ProgressBar min={property.min} max={property.max} value={property.value} />
                        </div>
                    </div>))
                }
            </div>

            <div className={styles.financialInfo}>
                <div className={styles.machinePrice}>
                    {(!machine.price || (!machine.showPrice && !machine.showPriceForGuestUsers)) &&
                        <a className={cn("btn btn--outlined justify-content-center")} href={machine.requestQuotationByEmail ?? ""}>{translationState["recommendedMachinesTranslations.requestProposal"]}</a>}
                    {!isLoggedIn && machine.showPrice && !machine.showPriceForGuestUsers &&
                        <a className={cn("btn btn--outlined justify-content-center")} href={loginLink}>{translationState["recommendedMachinesTranslations.logInToSeeThePrice"]}</a>}
                    {(machine.price && machine.showPrice && (isLoggedIn || machine.showPriceForGuestUsers)) && <p>{machine.price}</p>}
                </div>
                <div className={styles.machineDeliveryInfo}>
                    {machine.deliveryTime
                        ? <p>{translationState["recommendedMachinesTranslations.deliveryTimeNote"]} <span className="font-weight-bold">{machine.deliveryTime}</span>.</p>
                        : <p>{translationState["recommendedMachinesTranslations.deliveryTimeNoteUnavailable"]}</p>
                    }
                </div>
            </div>
        </div>
    </a>
}